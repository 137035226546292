import posthog from 'posthog-js'
import { useState } from 'react';


function CookieBanner() {
  const [showBanner, setShowBanner] = useState(true);
  const acceptCookies = () => {
    posthog.opt_in_capturing();
    setShowBanner(false);
  };

  const declineCookies = () => {
    posthog.opt_out_capturing();
    setShowBanner(false);
  };


  return (
    <>
      {showBanner && (
        <div className="fixed bottom-0 left-0 right-0 bg-white shadow-lg p-4 md:p-6 lg:p-8 rounded-t-lg border-t-2 border-indigo-500 flex flex-col md:flex-row items-center justify-between space-y-4 md:space-y-0 md:space-x-4 inset-x-0 mx-auto max-w-4xl">
          <p className="text-sm md:text-base text-gray-700">
            We use tracking cookies to understand how you use the product and help us improve it.
            Please accept cookies to help us improve.
          </p>
          <div className="flex items-center space-x-2">
            <button
              type="button"
              className="px-4 py-2 text-white bg-indigo-500 hover:bg-indigo-600 rounded-md shadow focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50"
              onClick={acceptCookies}
            >
              Accept cookies
            </button>
            <button
              type="button"
              className="px-4 py-2 text-gray-700 bg-gray-200 hover:bg-gray-300 rounded-md shadow focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
              onClick={declineCookies}
            >
              Decline cookies
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CookieBanner;
