import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import PrivateumzugeHero from '../../images/Umzuge/Privateumzuge.jpg' 







const Privateumzüge = () => {
    return (
        <section>
            <ScrollToTop />
            <NavBar />
            <section className="text-gray-600 body-font mt-12">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img alt="content" className="object-cover object-center h-full w-full" src={PrivateumzugeHero} />
                        </div>

                        <div className="flex flex-col sm:flex-row mt-10">

                            <div className="sm:w-full sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">

                                <p className='text-2xl mb-2 text-orange-master'>
                                    Privateumzüge
                                </p>
                                <p>
                                    Ob nah oder fern – ein reibungsloser und stressfreier Umzug, 
                                    das ist der Anspruch, dem wir uns stellen, deutschland- und 
                                    europaweit oder einfach nur innerhalb von Rhein/Ruhrgebiet. 
                                    Unser Umzugsservice auf einen Blick: Vom günstigen Studentenumzug, 
                                     bis hin zum "All-Inclusive-Umzug".
                                    Wenn Sie die gesamte Arbeit uns überlassen: 1A Euroumzug bietet Ihnen 
                                    zum Thema Umzug eine breite Palette von Möglichkeiten. Haben Sie bereits 
                                    eigene Umzugshelfer und benötigen "nur" einen modern ausgestatteten 
                                    Umzugswagen mit Fahrer? Er hilft Ihnen, Ihre Umzugsgüter transportgerecht
                                     zu beladen und fährt den LKW anschließend sicher zum gewünschten Umzugsort.
                                      Privater Umzug in Rhein/Ruhrgebiet oder möchten Sie sich lieber 
                                      ganz entspannt zurücklehnen und beobachten, wie 1A Euroumzug den 
                                      Full-Service Umzug für Sie erledigt, mit Einpackservice, Möbelmontage 
                                      oder Küchenmontage, Einrichtung von Halteverbotszonen, falls 
                                      erforderlich und evtl. Entsorgung des von Ihnen nicht mehr benötigten
                                       Inventars. Auf Wunsch übernehmen wir die gesamte Wohnungsauflösung
                                        bis hin zur Übergabe Ihrer besenreinen Räumlichkeiten an den Vermieter.
                                </p>
                                <Link to='/angebot-anfragen' className="mt-4 text-indigo-500 justify-center w-full inline-flex items-center ">
                                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg font-medium text-gray-900 rounded-lg group bg-gradient-to-r from-red-800 to-red-600 hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:from-red-700 hover:to-red-800 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Angebot Anfragen
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </section>
    );
};

export default Privateumzüge;
