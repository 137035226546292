import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import mainImage from '../../images/Umzuge/Buroumzuge.jpg'


const Auspackservice = () => {
    return (
        <section>
            <ScrollToTop />
            <NavBar />
            <section className="text-gray-600 body-font mt-12">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img alt="content" className="object-cover object-center h-full w-full" src={mainImage} />
                        </div>

                        <div className="flex flex-col sm:flex-row mt-10">

                            <div className="sm:w-full sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">

                                <p className='text-2xl mb-2 text-orange-master'>
                                    Ein- und Auspacken
                                </p>
                                <p>
                                    Ein- und Auspacken 1A Euroumzug bietet Ihnen für
                                    jeden Transport optimale und auf Ihren Bedarf
                                    zugeschnittene Verpackungsmöglichkeiten. Von günstigen
                                    Standardkartons bis hin zu individuell angefertigten Verpackungen,
                                    z.B. für Ihre Kunstobjekte und Antiquitäten. Mit unsern
                                    Faltkartons können Sie Ihr Umzugsgut sicher und zuverlässig von A nach B
                                    transportieren. Größe: 58 x 32 x 33 cm, 2-wellig, EE-Welle.
                                    Umzugskarton NEU: 2,99 €
                                </p>
                                <p>Gerne können sie sich auch Kartons ausleihen. Nach zurücksenden dieser an uns, erhalten sie die Hälfte zurück.</p>
                                <ul className='ml-2 list-disc list-inside pace-y-4 text-gray-500 list-disc list-inside dark:text-gray-400'>
                                    <li className='mr-4'>Umzugskartons</li>
                                    <li className='mr-4'>Bücherkartons</li>
                                    <li className='mr-4'>Hängeregisterkartons</li>
                                    <li className='mr-4'>Flaschenkartons</li>
                                    <li className='mr-4'>Flaschenhüllen</li>

                                </ul>
                                <Link to='/angebot-anfragen' className="mt-4 text-indigo-500 justify-center w-full inline-flex items-center ">
                                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg font-medium text-gray-900 rounded-lg group bg-gradient-to-r from-red-800 to-red-600 hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:from-red-700 hover:to-red-800 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Angebot Anfragen
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </section>
    );
};

export default Auspackservice;
