import React from 'react';
import img2 from '../images/2.png';
import img3 from '../images/3.png';
import img4 from '../images/crane-icon.png';
import { Link } from 'react-router-dom';
import { Icon } from 'react-icons-kit';
import { euro } from 'react-icons-kit/fa/euro';
import PlaneImage from '../images/Planen.png'
import Verpackungen from '../images/Verpackungen-und-Kartons.png'
import Transport from '../images/Transport.png'


const Services = () => {

    return (
        <div id="services" className="" >
            <section data-aos="zoom-in-down">
                <div className="my-4 py-4">
                    <h2 className="my-2 text-center text-3xl text-orange-master uppercase font-bold">DIENSTLEISTUNGEN</h2>
                    <div className='flex justify-center'>
                        <div className='w-24 border-b-4 border-orange-master'></div>
                    </div>
                    <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-gray-600">
                        Wenn Sie ein Angebot auf stundenbasis wünschen sehen sie hier unsere preisliste.
                    </h2>
                </div>

                <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2  text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img2} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Zwei Umzugshelfer </h2>
                                <p className="text-lg font-medium">
                                    Umzugshelfer für 3 Std+Fahrzeug LKW (3,5 t)</p>
                                <div className="flex items-center justify-center">
                                    <p className="text-4xl text-orange-master	 font-mono font-bold m-2">299</p>
                                    <p className=" text-orange-master font-mono"><Icon icon={euro} size={40} /></p>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Link to="/angebot-anfragen" className=" text-white bg-gradient-to-r from-red-800 to-red-600 hover:from-red-700 hover:to-red-800 inline-flex px-6 py-3 my-4 text-md shadow-xl rounded-xl sm:w-auto sm:mb-0">
                                        Jetzt Buchen
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center">
                                    <p className="text-xl font-mono font-bold m-2">Jede weitere Std</p>
                                </div>
                                <div className="flex items-center justify-center">
                                    <p className="text-4xl text-orange-master	 font-mono font-bold m-2">129</p>
                                    <p className=" text-orange-master	 font-mono"><Icon icon={euro} size={40} /></p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2  text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img3} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Drei Umzugshelfer </h2>
                                <p className="text-lg font-medium">
                                    Umzugshelfer für 3 Std+Fahrzeug LKW (3,5 t)</p>
                                <div className="flex items-center justify-center">
                                    <p className="text-4xl text-orange-master	 font-mono font-bold m-2">399</p>
                                    <p className=" text-orange-master	 font-mono"><Icon icon={euro} size={40} /></p>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Link to="/angebot-anfragen" className=" text-white bg-gradient-to-r from-red-800 to-red-600 hover:from-red-700 hover:to-red-800 inline-flex px-6 py-3 my-4 text-md shadow-xl rounded-xl sm:w-auto sm:mb-0">
                                        Jetzt Buchen
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center">
                                    <p className="text-xl font-mono font-bold m-2">Jede weitere Std</p>
                                </div>
                                <div className="flex items-center justify-center">
                                    <p className="text-4xl text-orange-master	 font-mono font-bold m-2">149</p>
                                    <p className=" text-orange-master	 font-mono"><Icon icon={euro} size={40} /></p>
                                </div>
                            </div>
                        </div>
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2  text-sm">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out" src={img4} />
                                <h2 className="font-semibold my-4 text-2xl text-center">Vier Umzugshelfer </h2>
                                <p className="text-lg font-medium">
                                    Umzugshelfer für 3 Std+Fahrzeug LKW + Umzugslift (3,5 t)</p>
                                <div className="flex items-center justify-center">
                                    <p className="text-4xl text-orange-master font-mono font-bold m-2">569</p>
                                    <p className=" text-orange-master font-mono"><Icon icon={euro} size={40} /></p>
                                </div>
                                <div className="flex items-center justify-center">
                                    <Link to="/angebot-anfragen" className=" text-white bg-gradient-to-r from-red-800 to-red-600 hover:from-red-700 hover:to-red-800 inline-flex px-6 py-3 my-4 text-md shadow-xl rounded-xl sm:w-auto sm:mb-0">
                                        Jetzt Buchen
                                    </Link>
                                </div>
                                <div className="flex items-center justify-center">
                                    <p className="text-xl font-mono font-bold m-2">Jede weitere Std</p>
                                </div>
                                <div className="flex items-center justify-center">
                                    <p className="text-4xl text-orange-master	 font-mono font-bold m-2">259</p>
                                    <p className=" text-orange-master	 font-mono"><Icon icon={euro} size={40} /></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="text-gray-600 body-font">
                <div className="container px-5 py-12 mx-auto">
                    <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                        <div className="sm:w-64 sm:h-64 h-64 w-64  sm:mr-10 inline-flex items-center justify-center rounded-full text-indigo-500 flex-shrink-0">
                            <img src={PlaneImage} alt='img' />
                        </div>
                        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                            <h2 className="text-orange-master text-3xl title-font font-medium mb-2">Planen</h2>
                            <p className="leading-relaxed text-base">
                                Sie müssen sich im Vorfeld um nichts kümmern,
                                denn wir bringen alles direkt mit, von Umzugskartons
                                über Spanngurte bis hin zu Spezialausrüstung für
                                komplexe Umzugsgüter. Für Sie hat dies den Vorteil,
                                dass Sie einen teuren Kauf dieser Ausrüstung sparen
                                können! Zusätzlich bieten wir ihnen an, einen Blick
                                in unsere Checkliste zu werfen, damit sie auch nichts
                                vergessen!</p>
                        </div>
                    </div>
                    <div className="flex items-center lg:w-3/5 mx-auto border-b pb-10 mb-10 border-gray-200 sm:flex-row flex-col">
                        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                            <h2 className="text-orange-master text-3xl title-font font-medium mb-2">Verpackungen und Kartons</h2>
                            <p className="leading-relaxed text-base">
                                1A Euroumzug bietet Ihnen für jeden Transport optimale
                                und auf Ihren Bedarf zugeschnittene Verpackungsmöglichkeiten.
                                Ob es günstige Standardkartons oder doch individuell
                                angefertigten Verpackungen werden sollen, z.B.
                                für Ihre Kunstobjekte und Antiquitäten.
                                Mit dem Service von 1A Euroumzug, sind sie rundum
                                versorgt!</p>
                        </div>
                        <div className="sm:w-32 sm:order-none order-first sm:h-32 h-20 w-20 sm:ml-10 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                            <div className="sm:w-64 sm:h-64 h-64 w-64  sm:mr-10 inline-flex items-center justify-center rounded-full text-indigo-500 flex-shrink-0">
                                <img alt='img' src={Verpackungen} />
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center lg:w-3/5 mx-auto sm:flex-row flex-col">
                        <div className="sm:w-64 sm:h-64 h-64 w-64  sm:mr-10 inline-flex items-center justify-center rounded-full text-indigo-500 flex-shrink-0">
                            <img alt='img' src={Transport} />
                        </div>
                        <div className="flex-grow sm:text-left text-center mt-6 sm:mt-0">
                            <h2 className="text-orange-master text-3xl title-font font-medium mb-2">Transport</h2>
                            <p className="leading-relaxed text-base">Unsere Möbelwagen sind für jeden Umzug geeignet. Um alle Anforderungen und Distanzen Ihrer Umzüge zu meistern. 1A Euroumzug garantiert Ihnen die neueste Ausrüstung. Dazu gehören natürlich auch unsere permanent gewarteten Fahrzeuge sowie geschultes Personal.</p>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;