import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import { useDocTitle } from '../../components/CustomHook';
import allgemeinenImage from '../../images/Umzuge/allgemeinen.png'
import Privateumzuge from '../../images/Umzuge/Privateumzuge.jpg'
import Behordenumzuge from '../../images/Umzuge/Behordenumzuge.jpg'
import Firmenumzuge from '../../images/Umzuge/Firmenumzuge.jpg'
import Seniorenumzuge from '../../images/Umzuge/Seniorenumzuge.jpg'
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';

const UmzugsservicePage = () => {
  useDocTitle("1A Euroumzug");
  return (
    <section>
      <ScrollToTop />
      <NavBar />
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col">
            <div className="flex flex-wrap sm:flex-row flex-col py-6 mb-12">
              {/* <h1 className="sm:w-2/5 text-gray-900 font-medium title-font text-2xl mb-2 sm:mb-0">Space The Final Frontier</h1>
              <p className="sm:w-3/5 leading-relaxed text-base sm:pl-10 pl-0">Street art subway tile salvia four dollar toast bitters selfies quinoa yuccie synth meditation iPhone intelligentsia prism tofu. Viral gochujang bitters dreamcatcher.</p> */}
            </div>
          </div>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4">
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={allgemeinenImage} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Umzüge im Allgemeinen</h2>
              <p className="text-base leading-relaxed mt-2">Egal ob Privat  Büro  Senioren Behörden Firmenumzug. Mit uns erfahren sie einen professionellen Ablauf. Selbst bei einfachem  Transport  helfen wir Ihnen gerne! </p>
              <Link to='/umzuge/Umzuge-im-allgemeinen' className="text-indigo-500 inline-flex items-center mt-3">
                Erfahren Sie mehr

                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Behordenumzuge} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Privateumzüge</h2>
              <p className="text-base leading-relaxed mt-2">Ob nah oder fern – ein reibungsloser und stressfreier Umzug, das ist der Anspruch, dem wir uns stellen, deutschland- und europaweit oder einfach nur innerhalb von Rhein/Ruhrgebiet.</p>
              <Link to='/umzuge/Privateumzuge' className="text-indigo-500 inline-flex items-center mt-3">
                Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Privateumzuge} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Behördenumzüge</h2>
              <Link to='/umzuge/Behordenumzuge  ' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Firmenumzuge} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Firmenumzüge</h2>
              <Link to='/umzuge/Firmenumzuge' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
            <div className="p-4 md:w-1/3 sm:mb-0 mb-6">
              <div className="rounded-lg h-64 overflow-hidden">
                <img alt="content" className="object-cover object-center h-full w-full" src={Seniorenumzuge} />
              </div>
              <h2 className="text-xl font-medium title-font text-gray-900 mt-5">Seniorenumzüge</h2>
              <Link to='/umzuge/Seniorenumzuge' className="text-indigo-500 inline-flex items-center mt-3">Erfahren Sie mehr
                <svg fill="none" stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </section>
  );
};

export default UmzugsservicePage;
