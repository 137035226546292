import React from 'react';
import { useDocTitle } from '../../components/CustomHook';
import Footer from '../../components/Footer';
import NavBar from '../../components/Navbar/NavBar';
import ScrollToTop from '../../components/ScrollToTop';


const Impressum = () => {
    useDocTitle("1A Euroumzug");    return (
        <>
        <ScrollToTop/>
            <div>
                <NavBar />
            </div>

            <div className='container px-5 py-24 mx-auto'>
                <p className='mb-10 text-4xl text-red-600'>Impressum</p>
                <p className='mt-5 text-xl'>Verantwortlich für den Inhalt:</p>
                <div>
                    <p>Manar Alkannas</p>
                    <p>Herzogstraße 10A</p>
                    <p>44651 Herne</p>
                </div>

                <p className='mt-5 text-xl'>Kontakt:</p>
                <div>
                    <p>Telefon (Festnetz): 02325 9085475</p>
                    <p>Telefon (Mobil): 01577 6590900</p>
                    <p>E-Mail: info@1aeuroumzug.de</p>
                </div>

                <div className='mt-4'>
                    <p>Steuernummer: 325/5003/8588</p>
                    <p>Umsatzsteuer-ID: DE34026841</p>
                </div>

                <p className='mt-5 text-xl'>Haftungsausschluss:</p>
                <div>
                    <p>Die Inhalte dieser Website wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte kann jedoch keine Gewähr übernommen werden.</p>

                </div>

                <p className='mt-5 text-xl'>Urheberrecht:</p>
                <div>
                    <p>
                        Die durch den Seitenbetreiber erstellten Inhalte und Werke auf dieser Website unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.                </p>

                </div>

                <p className='mt-5 text-xl'>Datenschutz:</p>
                <div>
                    <p>
                        Weitere Informationen zum Umgang mit personenbezogenen Daten finden Sie in unserer Datenschutzerklärung.

                    </p>

                </div>


            </div>

            <Footer />

        </>
    )
}

export default Impressum;