import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import mainImage from '../../images/Umzugsservice/Halteverbot-einrichten.jpg'


const Halteverbot = () => {
    return (
        <>
            <NavBar />
            <ScrollToTop />
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img alt="content" className="object-cover object-center h-full w-full" src={mainImage} />
                        </div>
                        <div className="flex flex-col sm:flex-row mt-10">
                            <div className="sm:w-2/4 text-center sm:pr-8 sm:py-8">

                                <div className="flex flex-col items-center text-center justify-center">
                                    <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">1A Euroumzug </h2>
                                    <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
                                    <p className="text-base">
                                    </p>
                                    <p className='mt-2 font-bold text-center'>ist von der Planung bis zur Durchführung
                                        Ihres Umzuges für Sie da. Unabhängig davon, um was es sich handelt – unser
                                        tatkräftiges Team aus erfahrenen Möbelpackern greift Ihnen unter
                                        die Arme. Wir transportieren Ihr persönliches Hab und Gut von A nach
                                        B und montieren Ihre Möbelstücke fachgerecht und zuverlässig. Aber was
                                        darf bei einer reibungslosen Umzugsplanung auf keinen Fall fehlen?
                                        Das Beantragen von Halteverboten!
                                        Wir holen alle erforderlichen Genehmigungen ein, die notwendig sind, um eine Halteverbotszone einzurichten.</p>
                                </div>
                            </div>
                            <div className="sm:w-2/4 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                                <p className='mb-2 font-bold'>Ihr Vorteil: </p>
                                <p className="leading-relaxed text-lg mb-4">
                                    Wenn Sie eine Halteverbotszone einrichten, sorgen Sie
                                    für einen besseren Ablauf des Umzuges. So können wir unsere Transporter
                                    direkt auf der gekennzeichneten Fläche parken, ohne von parkenden
                                    Autos blockiert zu werden. Beliebig oft kann die Parkfläche an- und
                                    abgefahren werden. Da besonders in Großstädten Parkplatzmangel herrscht, ist es umso praktischer,
                                    bei Privat- und Firmenumzügen eine Halteverbotszone einzurichten.
                                </p>
                                <p className='text-xl font-bold text-center'>Im Duchschnitt kostet ein Halteverbot
                                    pro Adresse ca. 100 €</p>
                                <Link to='/angebot-anfragen' className="mt-4 text-indigo-500 justify-center w-full inline-flex items-center ">
                                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg font-medium text-gray-900 rounded-lg group bg-gradient-to-r from-red-800 to-red-600 hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:from-red-700 hover:to-red-800 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Angebot Anfragen
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default Halteverbot;
