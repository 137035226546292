import React from 'react';
import { Link, Router } from 'react-router-dom';
import ScrollToTop from '../../components/ScrollToTop';


const NavLinks = () => {
  return (
    <>
    <ScrollToTop />
    <div className="relative inline-block">
        <Link smooth to="/Pages/UnsereGalerie">
          <a
            className="px-4 font-extrabold text-gray-500 hover:text-red-700 focus:outline-none"
          >
            Unsere Galerie
          </a></Link>
      </div>
      <Link className="px-4 font-extrabold text-gray-500 hover:text-red-700" smooth to="/">
        Hauptseite
      </Link>
      <div className="relative inline-block">
        <Link to='/umzuge'>
          <a
            className="px-4 font-extrabold text-gray-500 hover:text-red-700 focus:outline-none"
          >
            Umzüge
          </a>
        </Link>

      </div>
      <div className="relative inline-block">
        <Link to='/umzugsservice'>
          <a
            className="px-4 font-extrabold text-gray-500 hover:text-red-700 focus:outline-none"
          >
            Umzugsservice
          </a></Link>

      </div>
      <div className="relative inline-block">
        <Link to='/jobs'>
          <a
            className="px-4 font-extrabold text-gray-500 hover:text-red-700 focus:outline-none"
          >
            Jobs
          </a></Link>
      </div>
      <div className="relative inline-block">
        <Link smooth to="/pages">
          <a
            className="px-4 font-extrabold text-gray-500 hover:text-red-700 focus:outline-none"
          >
            FAQ
          </a></Link>
      </div>
      <Link
      className="text-white bg-gradient-to-r from-red-800 to-red-600 hover:from-red-700 hover
      :to-red-800 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"
       smooth to="/angebot-anfragen">
        Kontaktiere uns
      </Link>
    </>
  );
};

export default NavLinks;