import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import mainImage from '../../images/Umzuge/Behordenumzuge details.jpg'


const Behordenumzuge = () => {
    return (
        <section>
            <ScrollToTop />
            <NavBar />
            <section className="text-gray-600 body-font mt-12">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img alt="content" className="object-cover object-center h-full w-full" src={mainImage} />
                        </div>

                        <div className="flex flex-col sm:flex-row mt-10">

                            <div className="sm:w-full sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">

                                <p className='text-2xl mb-2 text-orange-master'>
                                    Behöredenumzüge
                                </p>
                                <p>
                                    Bekommen Sie umzüge von verschiedenen Behörden finanziert?
                                </p>
                                <ul className='ml-2 list-disc list-inside pace-y-4 text-gray-500 list-disc list-inside dark:text-gray-400'>
                                    <li className='mr-4'>Hartz IV / Jobcenter</li>
                                    <li className='mr-4'>Arbeitsagentur</li>
                                    <li className='mr-4'>Sozialamt</li>
                                    <li className='mr-4'>Rentenkassen</li>
                                    <li className='mr-4'>Krankenkassen</li>
                                    <li className='mr-4'>ETC..</li>

                                </ul>
                                <p>Haben Sie Anspruch auf Hartz IV? Möchten Sie Ihren Umzug von uns durchführen lassen?
                                    Dann sprechen Sie zuerst mit dem zuständigen Jobcenter ab, um die Finanzierung des Umzugs zu sichern.
                                    Gründe für die Kostenübernahme eines Hartz-IV-Umzugs:
                                </p>
                                <ul className='my-2 pace-y-4 text-gray-500 list-disc list-inside dark:text-gray-400'>
                                    <li className='mr-4'>Sie erwarten Familienzuwachs und die alte Wohnung ist nun zu klein.</li>
                                    <li className='mr-4'>Sie sind gesundheitlich beeinträchtigt und Ihnen kann daher das Wohnen in Ihrer alten Wohnung nicht mehr zugemutet werden.</li>
                                    <li className='mr-4'>Sie haben eine Arbeitsstelle gefunden und Ihre alte Wohnung liegt zu weit entfernt</li>
                                    <li className='mr-4'>Ihre alte Wohnung ist durch nicht von Ihnen verursachte Schäden unbewohnbar.</li>
                                    <li className='mr-4'>hnen wurde unverschuldet gekündigt.</li>
                                    <li className='mr-4'>Sie haben sich scheiden lassen und Ihr Ehepartner bleibt in der alten Wohnung.</li>
                                </ul>
                                <Link to='/angebot-anfragen' className="mt-4 text-indigo-500 justify-center w-full inline-flex items-center ">
                                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg font-medium text-gray-900 rounded-lg group bg-gradient-to-r from-red-800 to-red-600 hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:from-red-700 hover:to-red-800 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Angebot Anfragen
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </section>
    );
};

export default Behordenumzuge;
