import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import mainImage from '../../images/Umzugsservice/Mobel_einlagern.jpg'


const Mobel_einlagern = () => {
    return (
        <section>
            <ScrollToTop />
            <NavBar />
            <section className="text-gray-600 body-font mt-12">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img alt="content" className="object-cover object-center h-full w-full" src={mainImage} />
                        </div>

                        <div className="flex flex-col sm:flex-row mt-10">

                            <div className="sm:w-full sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">

                                <p className='text-2xl mb-2 text-orange-master'>
                                    Einlagern
                                </p>
                                <p className='my-4'>
                                    1A Euroumzug bietet Ihnen praktischen Lagerräume,
                                    den Sie für die kurzfristige Lagerung nutzen können.
                                    Das ist aber noch nicht alles. Wir bieten Ihnen
                                    keinen leeren Raum, in dem Sie Ihre Möbel aufbewahren
                                    können, sondern füllen ihn mit Service und Komfort für
                                    Sie. Sie Selfstorage in einem hervorragenden Gesamtpaket
                                    mit jeder Menge Vorteile:
                                </p>
                                <p className='mt-2 text-2xl'>24 Sicherheitskontrolle:</p>
                                <p>

                                    Sicherheit ist ein Aspekt der Selbstlagerung, der
                                    für viele Menschen bei der Lagerung von Möbeln extrem
                                    wichtig ist. Wir tun es auch: Bei uns werden Ihre Möbel
                                    24 Stunden am Tag, sieben Tage die Woche per Video überwacht,
                                    und es gibt geschultes Personal vor Ort. Nichts geht verloren
                                    und du bekommst alles zurück, so wie du es bei uns verwahrst.
                                </p>

                                <p className='mt-2 text-2xl'>Flexible Raumgrößen:</p>
                                <p>

                                    Gerade bei der Lagerung von Möbeln benötigen Sie auf
                                    jeden Fall ein paar Quadratmeter mehr, so dass Sie auf Lagerräume
                                    bis zu einer Größe von 30 m2 zugreifen können.
                                </p>

                                <p className='mt-2 text-2xl'>Platzbedarf bequem berechnen lassen:</p>
                                <p>

                                    Wenn Sie nicht sicher sind, wie viel Platz Sie in Ihrer
                                    Selfstorage-Einheit benötigen, helfen wir Ihnen gerne
                                    weiter. Mit unserem praktischen Raumrechner können Sie
                                    ganz einfach die Größe des Raumes berechnen, den Sie im
                                    Voraus benötigen. Am Ende können Sie nur für das,
                                    was Sie brauchen, im Stauraum verwenden und bezahlen.
                                </p>

                                <p className='mt-2 text-2xl'>Privat und gewerblich:</p>
                                <p>

                                    Wir sind für alle da! Dies gilt sowohl für Ihre
                                    Selbstlagerung als auch für gewerbliche Nutzer,
                                    z.B. wenn Sie Bei uns Büromöbel lagern möchten.
                                </p>

                                <p className='mt-2 text-2xl'>Gratis-Transporter:</p>
                                <p>

                                    Um Ihre Möbeln zu transportieren,
                                    bieten wir Ihnen einen kostenlosen
                                    Transporter, von dem Sie Ihre Möbel
                                    abholen und für uns fahren können. Voller
                                    Service vom ersten Tag an!
                                </p>
                                <Link to='/angebot-anfragen' className="mt-4 text-indigo-500 justify-center w-full inline-flex items-center ">
                                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg font-medium text-gray-900 rounded-lg group bg-gradient-to-r from-red-800 to-red-600 hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:from-red-700 hover:to-red-800 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Angebot Anfragen
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </section>
    );
};

export default Mobel_einlagern;
