import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import kws from '../images/clients/My-Hammer.png';
import geps from '../images/clients/Check24.png';
import protergia from '../images/clients/Umzug--365.png';
import helden from '../images/clients/Ruhrpott-Helden.png';


const clientImages = [
    { src: kws, url: 'https://www.my-hammer.de/auftragnehmer/1a-euroumzug/bewertungen?page=4', text: 'My-Hammer' },
    { src: protergia, url: 'https://www.umzug-365.de/umzugshelfer/PJ5V3/1a-euroumzug/', text: 'umzug 365' },
    { src: geps, url: 'https://umzug.check24.de/umzug/profil/eyqjpb', text: 'check24' },
    { src: helden, url: 'https://www.ruhrpotthelden.com/', text: 'Ruhrpott Helden' }
];

const clientImage = {
    height: '15rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
};

const Clients = () => {
    return (
        <section data-aos="fade-up">
            <div className="py-4">
                <h2 className=" text-center text-3xl text-orange-master uppercase font-bold">
                Unsere Geschäftspartner</h2>
                <div className='flex justify-center'>
                    <div className='w-24 rounded-2xl border-b-4 border-orange-master'></div>
                </div>
                <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-gray-600">
                Einige unserer Kunden.</h2>
            </div>
            <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                <div className="grid sm:grid-cols-3 lg:grid-cols-4">

                    {clientImages.map((client, index) => (
                        <div key={index} className="overflow-hidden p-3 flex flex-col items-center justify-center transition-all ease-in-out ">
                            <a href={client.url} target="_blank" rel="noopener noreferrer" className="text-center grid justify-items-center hover:opacity-70"  >
                                <img style={clientImage} src={client.src} alt="client" />
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}


export default Clients;