import React, { useRef, useState, useEffect } from 'react';
import Slider from 'react-slick'
import CourseCard from './CourseCard'


import Unsere_Galerie1 from '../../src/images/Galerie/1.jpg'
import Unsere_Galerie2 from '../../src/images/Galerie/2.jpg'
import Unsere_Galerie3 from '../../src/images/Galerie/3.jpg'
import Unsere_Galerie4 from '../../src/images/Galerie/4.jpg'
import Unsere_Galerie5 from '../../src/images/Galerie/5.jpg'
import Unsere_Galerie6 from '../../src/images/Galerie/6.jpg'
import Unsere_Galerie7 from '../../src/images/Galerie/7.jpg'
import Unsere_Galerie8 from '../../src/images/Galerie/8.jpg'
import Unsere_Galerie9 from '../../src/images/Galerie/9.jpg'
import Unsere_Galerie10 from '../../src/images/Galerie/10.jpg'
import Unsere_Galerie11 from '../../src/images/Galerie/11.jpg'
import Unsere_Galerie12 from '../../src/images/Galerie/12.jpg'
import Unsere_Galerie13 from '../../src/images/Galerie/13.jpg'
import Unsere_Galerie14 from '../../src/images/Galerie/14.jpg'


const Gallery = () => {

    var settings = {
        dots:true,
        infinite:false,
        speed:500,
        slidesToShow:4,
        slidesToScroll:2,
        responsive:[
        {
            breakpoint:1024,
            settings:{
                slidesToShow:4,
                slidesToScroll:2,
                infinite:false,
                dots:true
            }
        },
        {
            breakpoint:600,
            settings:{
                slidesToShow:2,
                slidesToScroll:2,
                infinite:false,
                dots:true
            }
        },
        {
            breakpoint:400,
            settings:{
                slidesToShow:1,
                slidesToScroll:1,
                infinite:false,
                dots:true
            }
        },
        ]
        
    }
    const images = [Unsere_Galerie1, Unsere_Galerie2, Unsere_Galerie3, Unsere_Galerie4, Unsere_Galerie5, Unsere_Galerie6, Unsere_Galerie7, Unsere_Galerie8, Unsere_Galerie9, Unsere_Galerie10, Unsere_Galerie11, Unsere_Galerie12, Unsere_Galerie13, Unsere_Galerie14];

    return (
    <section className='w-full py-24 p-4 bg-gray-master'>
        <div className='md:max-w-[1100px] m-auto max-w-[400px] gap-5'>
          <Slider {...settings} className='px-5'>
            {images && images.map((image) =>(
                <CourseCard linkImg={image}/>
            ))}
            </Slider>
        </div>
    </section>
    );
};

export default Gallery;
