import React from 'react';
import ScrollToTop from '../../components/ScrollToTop';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';

const FAQ = () => {
    return (
        <section>
            <ScrollToTop />
            <NavBar />
            <section className="text-gray-600 body-font mt-12">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="sm:w-full sm:pl-8 sm:py-8  border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                        <p className='text-2xl mb-2 text-orange-master font-bold'>
                            Ihre Fragen, unsere Antworten
                        </p>
                        <p className='my-4 text-lg text-orange-master'>
                            Was kostet ein Umzug?
                        </p>
                        <p className='mb-2'>Wie so oft: Das kommt darauf an.
                            Die Umzugskosten setzen sich aus vielen individuellen
                            Bausteinen zusammen und diese kann man allein anhand
                            von wenigen Eckdaten meist nur eingrenzend ermitteln.</p>

                        <p className='my-4 text-lg text-orange-master'>
                            Müssen Sie für die Erstellung meines Umzugsangebots zwingend vorher zu mir nach Hause kommen?
                        </p>
                        <p className='mb-2'>
                            Nicht zwingend, dennoch bieten wir ihnen einen
                            kostenlosen Besichtigungstermin an.
                            Wir bieten ihnen auch an via Whatsapp ode E-Mail
                            Bilder an uns zu schicken, sodass wir auf diesem
                            Weg ihnen ein faires Angebot unterbreiten können.
                        </p>

                        <p className='my-4 text-lg text-orange-master'>
                            Wie viel Vorlaufzeit benötigen Sie für meinen Umzug?
                        </p>
                        <p className='mb-2'>
                            Um Ihnen Ihren voraussichtlichen Wunschtermin bestätigen zu
                            können melden Sie sich gerne ca. vier Wochen vorher bei uns.
                            Die Vorlaufzeit scheint nicht mehr zu genügen?
                            Rufen sie uns an, unsere Mitarbeiter kommen ihnen gerne
                            entgegen um ihren Termin zu verwirklichen.
                        </p>

                        <p className='my-4 text-lg text-orange-master'>
                            Machen Sie nur Umzüge innerhalb von Bochum oder auch in eine andere Stadt?
                        </p>
                        <p className='mb-2'>
                            Wir bringen Sie überall hin. Nur in die Nachbarstadt,
                            innerhalb der Nachbarstadt oder auch quer um den Globus.
                            (Unser letzter Umzug ging nach Thailand)
                        </p>

                        <p className='my-4 text-lg text-orange-master'>
                            Wann können wir die Umzugskartons bekommen?
                            Wann müssen wir sie zurückgeben?
                        </p>
                        <p className='mb-2'>
                            Wenn Sie sich für einen Umzug mit uns entschieden haben,
                            erhalten Sie Umzugskartons so frühzeitig wie Sie diese haben möchten.
                            Es gibt keinen festgeschriebenen Zeitraum, in welchem Sie die Kartons zurückgeben müssen.
                            Sobald Sie die Kartons in aller Ruhe ausgepackt haben melden
                            Sie sich gerne erneut und schicken sie diese per Post etc. an uns zurück.
                        </p>

                        <p className='my-4 text-lg text-orange-master'>
                            Kann ich auch nur den Möbellift/Außenaufzug bei Ihnen leihen?
                        </p>
                        <p className='mb-2'>
                            Können Sie. Gerade, wenn Sie aus höheren Etagen
                            ausziehen oder Ihr neues Zuhause z. B. im vierten
                            Stock liegen sollte, kann häufig der Möbellift / Außenaufzug
                            dafür Sorgen, dass das zu kleine Treppenhaus umgangen wird.
                            Dann helfen wir Ihnen auch gerne nur bei diesem einem Möbelstück mit dem Möbellift.
                        </p>

                        <p className='my-4 text-lg text-orange-master'>
                            Wir möchten nur innerhalb des Hauses umziehen und benötigen keinen Umzugswagen,
                            machen Sie sowas auch?

                        </p>
                        <p className='mb-2'>
                            Ob Sie Ihre gesamte Wohnung innerhalb des Hauses wechseln möchten oder
                            auch nur das Mobiliar von einem Zimmer mit einem anderen tauschen möchten.
                            Wir helfen Ihnen gerne dabei.
                        </p>

                        <p className='my-4 text-lg text-orange-master'>
                            Warum gibt es in der Tageszeitung z.B. Umzüge für 400€?

                        </p>
                        <p className='mb-2'>
                            In Jede Branche gibt es Schwarze Schafe.
                        </p>

                        <p className='my-4 text-lg text-orange-master'>
                            Ist mein Umzug versichert?

                        </p>
                        <p className='mb-2'>
                            hr Hab und Gut ist mit einer Standartversicherung von 620 Euro je Kubikmeter versichert.
                             Das können wir mit unserem Qualitätsversprechen garantieren.
                            Zusatzversicherungen, wie Neuwertversicherungen oder höhere Versicherungen, 
                            können jeder Zeit vor dem Umzug abgeschlossen werden.
                        </p>
                    </div>
                </div>
            </section>
            <Footer />
        </section>
    )

}
export default FAQ;