import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import mainImage from '../../images/Umzuge/Buroumzuge.jpg'


const Seniorenumzuge = () => {
    return (
        <section>
            <ScrollToTop />
            <NavBar />
            <section className="text-gray-600 body-font mt-12">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img alt="content" className="object-cover object-center h-full w-full" src={mainImage} />
                        </div>

                        <div className="flex flex-col sm:flex-row mt-10">

                            <div className="sm:w-full sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">

                                <p className='text-2xl mb-2 text-orange-master'>
                                    Seniorenumzüge
                                </p>
                                <p>
                                    Wenn Sie umziehen, ist das Team von 1A Euroumzug Ihr
                                    kompetenter und lösungsorientierter Partner, der für
                                    jeden Sachgebiet eine breite Palette von Dienstleistungen bietet.
                                    Gerne übernehmen wir nach Ihrem Wunsch einen kompletten
                                    Ein- und Auspackservice, fachgerechte Demontage und Wiederaufbau
                                    Ihrer Möbel am gewünschten Zielort europaweit. Auch führen wir
                                    für Sie die Haushaltauflösungen und Renovierung der Wohnungen
                                    zur besenreinen Übergabe an den Vermieter durch. Vereinbaren
                                    Sie heute noch einen kostenlosen und unverbindlichen Besichtigungstermin
                                    und lassen Sie sich durch unseren Außendienstmitarbeiter professionell
                                    und bedarfsgerecht beraten.
                                    Hier einige unserer Leistungen im Überblick:
                                </p>
                                <ul className='mt-2 pace-y-4 text-gray-500 list-disc list-inside dark:text-gray-400' >
                                    <li>Umzugsservice von A bis Z</li>
                                    <li>Kostenlose Besichtigung</li>
                                    <li>Lieferung des Packmaterials</li>
                                    <li>Ein- und Auspacken der Kartons</li>
                                    <li>Fachgerechte Möbelmontage</li>
                                    <li>Küchenmontage nach Maß</li>
                                    <li>Entrümpelung und Entsorgung</li>
                                    <li>Renovierungsarbeiten</li>
                                </ul>
                                <p className='mt-2'>
                                Nach dem beladen des Umzugswagens mit Ihren Möbeln
                                 bringen wir Sie bequem und sicher mit einem PKW in 
                                 Ihr neues Zuhause. 1A Euroumzug kümmert sich um 
                                 Seniorenumzüge im Rhein/Ruhrgebiet.
                                </p>

                                <Link to='/angebot-anfragen' className="text-indigo-500 justify-center w-full inline-flex items-center ">
                                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg font-medium text-gray-900 rounded-lg group bg-gradient-to-r from-red-800 to-red-600 hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:from-red-700 hover:to-red-800 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Angebot Anfragen
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </section>
    );
};

export default Seniorenumzuge;
