import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import { FaShuttleVan } from "react-icons/fa";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from 'react-router-dom';
import ScrollToTop from '../components/ScrollToTop';
import { FaPhoneAlt } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";

import CustomAlert from './../components/thanks';
import Loading from './../components/loading';

const DemoProduct = (props) => {


    useDocTitle("1A Euroumzug");
    //the first step
    const [street1, setStreet1] = useState('')
    const [street2, setStreet2] = useState('')
    const [House_number1, setHouse_number1] = useState('')
    const [House_number2, setHouse_number2] = useState('')
    const [Postcode1, setPostcode1] = useState('')
    const [Postcode2, setPostcode2] = useState('')
    const [Location1, setLocation1] = useState('')
    const [Location2, setLocation2] = useState('')

    //the secund step
    const [Moving_from, setMoving_from] = useState('Hous');
    const [room, setroom] = useState('1');
    const [area, setarea] = useState('von 0 bis 40m²');
    const [move_to, setmove_to] = useState('Haus');
    const [assumption, setassumption] = useState('Privat');
    const [Pack, setPack] = useState(false);
    const [Dismantle, setDismantle] = useState(false);
    const [Assemble, setAssemble] = useState(false);
    const [Dismantle_kitchen, setDismantle_kitchen] = useState(false);
    const [Assemble_kitchen, setAssemble_kitchen] = useState(false);
    const [message, setmessage] = useState('');
    //the third step
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [isAccepted, setIsAccepted] = useState(false);
    //to handel the next and previos 
    const [step, setStep] = useState(1);
    const [errors, setErrors] = useState("")
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedValue, setSelectedValue] = useState('Flexibel');
    const [isDisabled, setIsDisabled] = useState(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);

    const handleDateChange = (date) => {
        const formatted = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
        setSelectedDate(formatted);
    };

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
        if (event.target.value === 'Flexibel') {
            setIsDisabled(true);
            setSelectedDate(null)
        }
        else
            setIsDisabled(false)
    };
    const clearErrors = () => { setErrors("") }

    const handleNext = () => {
        if (step === 1 && (!street1 || !House_number1 || !Postcode1 || !Location1 || !street2 || !House_number2 || !Postcode2 || !Location2)) {
            setErrors("Bitte füllen Sie alle erforderlichen Felder aus.");
            console.log(errors)
            return;
        }
        else if (step === 2 && (!Moving_from || !room || !area || !move_to || !assumption)) {
            console.log(Moving_from + room + area + move_to + assumption + message)
            setErrors("Bitte füllen Sie alle erforderlichen Felder aus.");
            return;
        }
        else if (step === 3 && (!firstName || !lastName || !email || !phone || !isAccepted)) {
            console.log(Moving_from + room + area + move_to + assumption + message)
            setErrors("Bitte füllen Sie alle erforderlichen Felder aus.");
            return;
        }
        else {
            clearErrors();
            setStep(step + 1);
        }
    };

    const handlePrevious = () => { setStep(step - 1) }

    const handleImageUpload = (e) => {
        const files = Array.from(e.target.files);
        const imageFiles = files.filter((file) => file.type.startsWith('image/'));
        setSelectedFiles(imageFiles);
    }
    const handlsubmet = async (e) => {
        setIsLoading(true);
        e.preventDefault();
        let fData = new FormData();
        fData.append('first_name', firstName)
        fData.append('last_name', lastName)
        fData.append('email', email)
        fData.append('phone_number', phone)
        fData.append('message', message)
        fData.append('street1', street1)
        fData.append('street2', street2)
        fData.append('House_number1', House_number1)
        fData.append('House_number2', House_number2)
        fData.append('Postcode1', Postcode1)
        fData.append('Postcode2', Postcode2)
        fData.append('Location1', Location1)
        fData.append('Location2', Location2)
        fData.append('Moving_from', Moving_from)
        fData.append('room', room)
        fData.append('area', area)
        fData.append('move_to', move_to)
        fData.append('assumption', assumption)
        fData.append('Pack', Pack)
        fData.append('Dismantle', Dismantle)
        fData.append('Assemble', Assemble)
        fData.append('Dismantle_kitchen', Dismantle_kitchen)
        fData.append('Assemble_kitchen', Assemble_kitchen)
        fData.append('selectedValue', selectedValue)
        fData.append('selectedDate', selectedDate)
        selectedFiles.forEach((file, index) => {
            fData.append('selectedFiles', file);
        });
        try {
            const response = await axios.post(
                `https://sendpdfbyemail.onrender.com/senfpdf`,
                fData, {
                responseType: 'blob',
            });
            if (response.status === 200)
                setShowAlert(true);
            else
                alert('errore!');
        }
        catch (error) { console.log(error); }
        finally { setIsLoading(false); }
    }

    return (
        <>
            {showAlert && (
                <CustomAlert
                    message="Vielen Dank!!"
                />
            )}
            {isLoading && <Loading message="Bitte warten..." />}
            <ScrollToTop />
            <div>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container my-8 px-4  lg:px-20" data-aos="zoom-in">
                    <form onSubmit={handlsubmet} >
                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <ScrollToTop />

                            {errors && (

                                <div className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                    <ScrollToTop />
                                    {errors}
                                </div>
                            )}
                            {step === 1 && (
                                <div>
                                    <ScrollToTop />

                                    <div className="flex">
                                        <h1 className="flex font-bold text-center lg:text-left text-orange-master uppercase text-4xl items-center"><span className='mr-2'><FaShuttleVan /></span>Express Formular</h1>
                                    </div>
                                    <div className="grid grid-flow-row grid-rows-5  md:grid-flow-col gap-5 mt-5">
                                        <div className='flex gap-4 items-center text-2xl'>
                                            <FaArrowAltCircleLeft size={40} />
                                            <p className='text-orange-master font-bold'>Umzug von:</p>

                                        </div>
                                        <div>
                                            <input
                                                name="street1"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="text"
                                                placeholder="Straße*"
                                                value={street1}
                                                onChange={(e) => setStreet1(e.target.value)}
                                                onKeyUp={clearErrors}
                                                required
                                            />
                                            {errors && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                                        </div>
                                        <div>
                                            <input
                                                name="House_number1"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="number"
                                                placeholder="Hausnr*"
                                                value={House_number1}
                                                onChange={(e) => setHouse_number1(e.target.value)}
                                                required
                                            />
                                            {errors && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                                        </div>
                                        <div>
                                            <input
                                                name="Postcode1"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="number"
                                                placeholder="PLZ*"
                                                value={Postcode1}
                                                onChange={(e) => setPostcode1(e.target.value)}
                                                required
                                            />
                                            {errors && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                                        </div>
                                        <div>
                                            <input
                                                name="Location1"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="text"
                                                placeholder="Ort*"
                                                value={Location1}
                                                onChange={(e) => setLocation1(e.target.value)}
                                                onKeyUp={clearErrors}
                                                required
                                            />
                                            {errors && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                                        </div>
                                        <div className='flex gap-4 items-center text-2xl'>
                                            <FaArrowAltCircleRight size={40} />
                                            <p className='text-orange-master font-bold'>Umzug nach:</p>
                                        </div>
                                        <div>
                                            <input
                                                name="street2"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="text"
                                                placeholder="Straße*"
                                                value={street2}
                                                onChange={(e) => setStreet2(e.target.value)}
                                                required
                                            />
                                            {errors && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                                        </div>
                                        <div>
                                            <input
                                                name="House_number2"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="number"
                                                placeholder="Hausnr*"
                                                value={House_number2}
                                                onChange={(e) => setHouse_number2(e.target.value)}
                                                required
                                            />
                                            {errors && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                                        </div>
                                        <div>
                                            <input
                                                name="Postcode2"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="number"
                                                placeholder="PLZ*"
                                                value={Postcode2}
                                                onChange={(e) => setPostcode2(e.target.value)}
                                                required
                                            />
                                            {errors && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                                        </div>
                                        <div>
                                            <input
                                                name="Locatio2"
                                                className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="text"
                                                placeholder="Ort*"
                                                value={Location2}
                                                onChange={(e) => setLocation2(e.target.value)}
                                                required
                                            />

                                        </div>
                                    </div>
                                    <div className='flex my-2 grid-cols-1 md:grid-cols-2 grid '>
                                        <div className="my-2 w-full ">
                                        </div>
                                        <div className="my-2 w-full ">
                                            <button type="button" onClick={handleNext} className="text-center items-center w-full focus:outline-none text-white bg-green-600 hover:bg-green-500 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 ">
                                                Weiter
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {step === 2 && (
                                <div>
                                    <ScrollToTop />
                                    <div className="flex">
                                        <h1 className="flex font-bold text-center lg:text-left text-orange-master uppercase text-4xl items-center"><span className='mr-2'><FaShuttleVan /></span>Express Formular</h1>
                                    </div>
                                    <div className="grid grid-flow-row grid-rows-2  md:grid-flow-col gap-5 mt-5">
                                        <div className='text-center'>
                                            <label for="Moving_from" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Umzug von
                                            </label>
                                            <select
                                                value={Moving_from}
                                                onChange={(e) => setMoving_from(e.target.value)}
                                                required
                                                id="Moving_from"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                <option>Hous</option>
                                                <option>Wohnung</option>
                                            </select>
                                        </div>
                                        <div className='text-center'>
                                            <label for="room" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Zimmer
                                            </label>
                                            <select
                                                value={room}
                                                onChange={(e) => setroom(e.target.value)}
                                                required
                                                id="room"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                <option>4</option>
                                                <option>5 oder mehr</option>
                                            </select>
                                        </div>
                                        <div className='text-center'>
                                            <label for="area" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Fläche
                                            </label>
                                            <select
                                                value={area}
                                                onChange={(e) => setarea(e.target.value)}
                                                required
                                                id="area"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <option>von 0 bis 40m²</option>
                                                <option>von 40 bis 80m²</option>
                                                <option>von 120 bis 160m²</option>
                                                <option>Über 160m²</option>
                                            </select>
                                        </div>
                                        <div className='text-center'>
                                            <label for="move_to" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Umzug nach

                                            </label>
                                            <select
                                                value={move_to}
                                                onChange={(e) => setmove_to(e.target.value)}
                                                required
                                                id="move_to"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">

                                                <option>Haus</option>
                                                <option>Wohnung</option>

                                            </select>
                                        </div>
                                        <div className='text-center'>
                                            <label for="assumption" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                                                Kostenübernahme
                                            </label>
                                            <select
                                                value={assumption}
                                                onChange={(e) => setassumption(e.target.value)}
                                                id="assumption"
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                                                <option>Privat</option>
                                                <option>Geschäftlich</option>
                                                <option>Behörde</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className='w-full mt-2 p-3'>
                                        <p>Zusatzservice</p>
                                    </div>
                                    <div className='grid grid-flow-row grid-rows-2 md:grid-flow-col gap-5 mt-2'>
                                        <div className="flex items-center mb-4 gap-4">
                                            <input
                                                id="einpacken-checkbox"
                                                type="checkbox"
                                                value="Kartons einpacken"
                                                onChange={(e) => setPack(e.target.checked)}
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                required
                                            />
                                            <label for="einpacken-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">  Kartons einpacken </label>
                                        </div>
                                        <div className="flex items-center mb-4 gap-4">
                                            <input
                                                id="montieren-checkbox"
                                                type="checkbox"
                                                value="Möbel demontieren"
                                                onChange={(e) => setDismantle(e.target.checked)}
                                                required
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="montieren-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Möbel demontieren </label>
                                        </div>
                                        <div className="flex items-center mb-4 gap-4">
                                            <input
                                                id="Küche-checkbox"
                                                onChange={(e) => setAssemble(e.target.checked)}
                                                required
                                                type="checkbox"
                                                value="Möbel montieren"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="Küche-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Möbel montieren </label>
                                        </div>
                                        <div className="flex items-center mb-4 gap-4">
                                            <input
                                                required
                                                id="Möbel-checkbox"
                                                type="checkbox"
                                                onChange={(e) => setDismantle_kitchen(e.target.checked)}
                                                value="Küche demontieren"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="Möbel-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                                                Küche demontieren
                                            </label>
                                        </div>
                                        <div className="flex items-center mb-4 gap-4">
                                            <input
                                                required
                                                id="Küche-demontieren-checkbox"
                                                type="checkbox"
                                                onChange={(e) => setAssemble_kitchen(e.target.checked)}
                                                value="Küche montieren"
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                            <label for="Küche-demontieren-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">  Küche montieren </label>
                                        </div>
                                    </div>
                                    <div className='w-full mt-2 p-3 text-lg'>
                                        <p>Oder Für ein besseres Angebot können sie uns Bilder zukommen lassen!</p>
                                        <p>Bilder von den Gütern hochladen</p>
                                    </div>
                                    <div>
                                        <div className="flex items-center justify-center w-full">
                                            <label for="dropzone-file" className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                                    <svg className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 16">
                                                        <path stroke="currentColor" sstrokelinejoin="round" ssstrokelinejoin="round" strokeWidth="2" d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2" />
                                                    </svg>
                                                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Klicken Sie zum Hochladen</span>oder per Drag & Drop</p>
                                                    <p className="text-xs text-gray-500 dark:text-gray-400">PNG, JPG (MAX. 800x400px)</p>
                                                    {selectedFiles.map((file, index) => (
                                                        <div key={index}>{file.name}</div>
                                                    ))}
                                                </div>
                                                <input
                                                    required
                                                    id="dropzone-file"
                                                    type="file"
                                                    className="hidden"
                                                    onChange={handleImageUpload}
                                                    accept="image/*"
                                                    multiple />
                                            </label>
                                        </div>
                                    </div>
                                    <div>
                                        <label for="message" className="block mt-2 p-3 text-lg font-medium text-gray-900 dark:text-white">Bemerkungen</label>
                                        <textarea
                                            id="message"
                                            value={message}
                                            onChange={(e) => setmessage(e.target.value)}
                                            rows="4"
                                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                            placeholder="Gerne können Sie uns zusätzliche Informationen schreiben.">
                                        </textarea>
                                    </div>
                                    <div>
                                    </div>
                                    <div className='flex my-2 grid-cols-1 md:grid-cols-2 grid gap-4'>
                                        <div className="my-2 w-full ">
                                            <button type="button" onClick={handlePrevious} className="text-center items-center w-full focus:outline-none text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 ">
                                                Zurück
                                            </button>
                                        </div>
                                        <div className="my-2 w-full ">
                                            <button
                                                type="button"
                                                onClick={handleNext}
                                                className="text-center items-center w-full focus:outline-none text-white bg-green-600 hover:bg-green-500 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 ">
                                                Weiter
                                            </button>
                                        </div>
                                    </div>
                                </div>)}
                            {step === 3 && (
                                <div>
                                    <ScrollToTop />
                                    <div className="flex">
                                        <h1 className="font-bold text-center lg:text-left text-orange-master uppercase text-4xl">  Fast geschafft! </h1>
                                    </div>
                                    <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                        <div>
                                            <input
                                                required
                                                name="first_name"
                                                className="required:border-red-500 w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="text"
                                                placeholder="Vorname*"
                                                id='first_name'
                                                value={firstName}
                                                onChange={(e) => setFirstName(e.target.value)}
                                            />
                                            {errors && <p className="text-red-500 text-sm">{errors.first_name}</p>}
                                        </div>
                                        <div>
                                            <input
                                                required
                                                name="last_name"
                                                className="required:border-red-500 w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="text"
                                                id='last_name'
                                                placeholder="Familienname*"
                                                value={lastName}
                                                onChange={(e) => setLastName(e.target.value)}
                                            />
                                            {errors && <p className="text-red-500 text-sm">{errors.last_name}</p>}
                                        </div>
                                        <div>
                                            <input
                                                required
                                                name="email"
                                                className="required:border-red-500 w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="email"
                                                id='email'
                                                placeholder="Email*"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            {errors && <p className="text-red-500 text-sm">{errors.email}</p>}
                                        </div>
                                        <div>
                                            <input
                                                required
                                                name="phone_number"
                                                className="required:border-red-500 w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                                type="number"
                                                id='phone_number'
                                                placeholder="Telefon*"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                            />
                                        </div>
                                        <div className='flex justify-between grid grid-cols-1 md:grid-cols-2'>
                                            <div className="flex items-center  gap-2 ">
                                                <input id="country-option-1"
                                                    type="radio"
                                                    name="countries"
                                                    value="Bestimmter Tag"
                                                    checked={selectedValue === 'Bestimmter Tag'}
                                                    onChange={handleRadioChange}
                                                    required
                                                    className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label for="country-option-1" className="block ms-2  text-lg font-medium text-gray-900 dark:text-gray-300">  Fixtermin </label>
                                            </div>
                                            <div className="flex items-center  gap-2">
                                                <input
                                                    required
                                                    id="country-option-2"
                                                    type="radio"
                                                    name="countries"
                                                    value="Flexibel"
                                                    checked={selectedValue === 'Flexibel'}
                                                    onChange={handleRadioChange}
                                                    className="w-4 h-4 border-gray-300 focus:ring-2 focus:ring-blue-300 dark:focus:ring-blue-600 dark:focus:bg-blue-600 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label for="country-option-2" className="block ms-2  text-lg font-medium text-gray-900 dark:text-gray-300"> Flexibel </label>
                                            </div>
                                        </div>

                                        <div className="relative w-full flex items-center gap-2">
                                            <div className="w-1/12 inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                                <svg className="w-6 h-6 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                                                </svg>
                                            </div>
                                            <label for='datePicker' className='w-11/12 bg-gray-50 border border-gray-300 text-gray-900 text-md rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
                                                <DatePicker
                                                    className='focus:outline-none bg-gray-50 text-xl'
                                                    id='datePicker'
                                                    selected={selectedDate}
                                                    onChange={handleDateChange}
                                                    dateFormat="dd/MM/yyyy"
                                                    placeholderText="Datum auswählen"
                                                    disabled={isDisabled}
                                                    minDate={new Date()}
                                                />
                                            </label>
                                        </div>
                                    </div>
                                    <div className='mt-6'>
                                        <div className="flex flex-nowrap items-center mb-4 gap-2 items-center">
                                            <input
                                                id="default-checkbox"
                                                type="checkbox"
                                                value=""
                                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                required
                                            />
                                            <label
                                                for="default-checkbox"
                                                className=" fle gap-1 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                                                checked={isAccepted}
                                                onChange={(e) => setIsAccepted(e.target.checked)}
                                            >
                                                Ja, ich stimme den
                                                <Link to='/AGB' className='ml-1 text-blue-600 hover:text-blue-900'>
                                                    AGB
                                                </Link> und den <Link to='/datenschutz' className='text-blue-600 hover:text-blue-900'>
                                                    Datenschutzbestimmungen
                                                </Link>
                                                von 1A Euroumzug zu.*</label>
                                        </div>
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                                        <div className="mt-6 w-full ">
                                            <button type="button"
                                                disabled={isLoading}
                                                onClick={handlePrevious}
                                                className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-gray-600 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline">
                                                Zurück
                                            </button>
                                        </div>
                                        <div className="mt-6 w-full ">
                                            <button
                                                disabled={isLoading}
                                                type='submet'
                                                className="uppercase text-sm font-bold tracking-wide bg-green-600 hover:bg-green-500 text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline">
                                                {isLoading ? 'Wird geladen...' : 'Senden'}
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </form>
                    <div className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-gradient-to-r from-red-800 to-red-600 rounded-2xl">
                        <div className="flex flex-col text-white">
                            <div className="flex my-4 w-2/3 lg:w-3/4">
                                <div className="flex flex-col">
                                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                </div>
                                <div className="flex flex-col">
                                    <h2 className="text-xl">Büro adresse</h2>
                                    <p className="text-gray-400">Herzogstraße 10A 44651 Herne</p>
                                </div>
                            </div>

                            <div className="flex  w-2/3 lg:w-1/2">
                                <div className="flex flex-col">
                                    <i className="fas fa-phone-alt pt-2 pr-2" />
                                </div>

                                <div className="flex flex-col">
                                    <h2 className="text-xl">Rufen Sie uns an</h2>
                                    <p className="text-gray-400">
                                        <a target='_black' href={`tel:+4915254123456`} className='flex items-center gap-2' ><FaWhatsapp />+49 15254123456</a>
                                        <a target='_black' href={`tel:+4923259085475`} className='flex items-center gap-2'  ><FaPhoneAlt />+49 23259085475</a>
                                    </p>

                                    <div className='mt-5'>
                                        <h2 className="text-lg">Eine E-Mail senden</h2>
                                        <p className="text-gray-400">info@1aeuroumzug.de</p>
                                    </div>

                                </div>
                            </div>

                            <div className="flex my-4 w-2/3 lg:w-1/2">
                                <a href="https://www.facebook.com/1aeuroumzug" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8 mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default DemoProduct;