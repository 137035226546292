import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import mainImage from '../../images/Umzugsservice/Kuchen-und-Mobelmontage.jpg'
import Kuchenmontage from '../../images/Umzugsservice/Kuchenmontage.png'
import Kuchenmontage3 from '../../images/1200-500/Kuchenmontage3.png'
import Kuchenmontage2 from '../../images/1200-500/Kuchenmontage2.png'
import Kuchenmontage4 from '../../images/1200-500/Kuchenmontage4.png'
import Kuchenmontage5 from '../../images/1200-500/Kuchenmontage5.png'
const Kuchen = () => {
    return (
        <section>
            <ScrollToTop />
            <NavBar />
            <section className="text-gray-600 body-font mt-12">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img alt="content" className="object-cover object-center h-full w-full" src={mainImage} />
                        </div>
                        <div className="flex flex-col sm:flex-row mt-10">
                            <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                                <div className="w-20 h-20 rounded-full inline-flex items-center justify-center text-gray-400">
                                    <img alt='img' src={Kuchenmontage} />
                                </div>
                                <div className="flex flex-col items-center text-center justify-center">
                                    <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Phoebe Caulfield</h2>
                                    <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
                                    <p className="text-base">Raclette knausgaard hella meggs normcore williamsburg enamel pin sartorial venmo tbh hot chicken gentrify portland.</p>
                                </div>
                            </div>
                            <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                                <p className="leading-relaxed text-lg mb-4">
                                    Die fachgerechte Kuchenmontage ist häufig schwieriger,
                                    als man sich dieses zunächst vorstellt. Damit Sie sich
                                    nicht selber damit auseinandersetzen müssen, bauen unsere
                                    Küchenmonteure Ihre Küche effizient und kompetent ein. Ob
                                    Sie eine neue Küche in Ihr Heim integrieren möchten oder
                                    die Küchenzeile in die neuen Wohnräume mitgebracht werden soll:
                                    Unsere Monteure sorgen für einen unkomplizierten Einbau.
                                    Dasselbe gilt übrigens für die Möbelmontage: Ob Schränke, Betten,
                                    Kommoden oder andere Möbelstücke – unsere Monteure bauen alles für
                                    Sie ab und auf.
                                </p>


                                <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
                                    <table className="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
                                        <thead className="text-xs text-white uppercase bg-orange-master dark:text-white">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                    Montage
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                    Pauschalpreise
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium  text-black whitespace-nowrap dark:text-blue-100">
                                                    Standardschlafzimmer
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                    ca. 90 €
                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                    Schlafzimmer mit Überbau
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                    135 - 165 €

                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                    Eckbank / Esstisch
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                    ca. 50 €

                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black text-blue-50 whitespace-nowrap dark:text-blue-100">
                                                    Kleiderschrank und andere einzelne Möbelstücke
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                    15 € / m

                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                    Montagefachmann
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                    37 - 40 €

                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                    Helfer (wenn nötig)
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                    18 - 20 €
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className='flex text-5xl my-5 justify-center'>
                                Küchenpreise
                                </p>
                                <div className="relative mt-10 overflow-x-auto shadow-md sm:rounded-lg">
                                    <table className="w-full text-sm text-left rtl:text-right text-blue-100 dark:text-blue-100">
                                        <thead className="text-xs text-white uppercase bg-orange-master dark:text-white">
                                            <tr>
                                                <th scope="col" className="px-6 py-3">
                                                Dienstleistung
                                                </th>
                                                <th scope="col" className="px-6 py-3">
                                                Preis
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium  text-black whitespace-nowrap dark:text-blue-100">
                                                Komplettküche bis 3 Meter
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                Ab 649,-
                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                Jeder weitere Meter
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                ab 100,-
                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                Armatur montieren
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                ab   59,-

                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black text-blue-50 whitespace-nowrap dark:text-blue-100">
                                                Einbauspüle montieren
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                ab   79,-

                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                Kochfeld einbauen	
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                ab   59,-

                                                </td>

                                            </tr>
                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                Elektroherd einbauen	
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                ab   99,-
                                                </td>

                                            </tr>

                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                Dunstabzughaube montieren		
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                ab   79,-

                                                </td>

                                            </tr>

                                            <tr className="bg-[#f5f6fa]">
                                                <th scope="row" className="px-6 py-4 font-medium text-black whitespace-nowrap dark:text-blue-100">
                                                Küchenarbeitsplatte montieren		
                                                </th>
                                                <td className="px-6 py-4 text-black">
                                                lfm ab 49,-
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/* <a href='/' className="text-indigo-500 justify-center w-full inline-flex items-center ">
                                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg font-medium text-gray-900 rounded-lg group bg-gradient-to-r from-red-800 to-red-600  group-hover:from-red-700 hover:to-red-800 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Kontaktiere uns
                                        </span>
                                    </button>
                                </a> */}
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section className="text-gray-600 body-font">
                <div className="container px-5 py-5 mx-auto">
                    <div className="flex flex-wrap -m-4">
                        <div className="xl:w-1/4 md:w-1/2 p-4">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <img className="h-50 rounded w-full object-cover object-center mb-6" src={Kuchenmontage3} alt="content" />
                                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">
                                    Pauschalpreis ab 849 ,- bei einer Gesamtlänge von ca. 5 Metern
                                </h3>
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                    L- Form Beispielküche
                                </h2>
                                <p className="leading-relaxed text-base">
                                    Verbindstück muss vom Experten vorgefertigt werden für die Montage.
                                </p>
                            </div>
                        </div>
                        <div className="xl:w-1/4 md:w-1/2 p-4">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <img className="h-50 rounded w-full object-cover object-center mb-6" src={Kuchenmontage2} alt="content" />
                                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">
                                Pauschalpreis ab 949,- bei einer Gesamtlänge von ca. 6 Metern
                                </h3>
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                    L-shape example kitchen with island
                                </h2>
                                <p className="leading-relaxed text-base">
                                Verbindstück muss vom Experten vorgefertigt werde​n für die Montage.

                                </p>
                                <p>

                                </p>
                            </div>
                        </div>
                        <div className="xl:w-1/4 md:w-1/2 p-4">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <img className="h-50 rounded w-full object-cover object-center mb-6" src={Kuchenmontage4} alt="content" />
                                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">
                                Pauschalpreis ab 749,- bei einer Gesamtlänge von ca. 4 Metern
                                </h3>
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                                Einfache Küchenzeile Beispielküche
                                </h2>
                            </div>
                        </div>
                        <div className="xl:w-1/4 md:w-1/2 p-4">
                            <div className="bg-gray-100 p-6 rounded-lg">
                                <img className="h-50 rounded w-full object-cover object-center mb-6" src={Kuchenmontage5} alt="content" />
                                <h3 className="tracking-widest text-indigo-500 text-xs font-medium title-font">auschalpreis ab 749,- bei einer Gesamtlänge von ca. 4 Metern</h3>
                                <h2 className="text-lg text-gray-900 font-medium title-font mb-4">U-Form Beispielküche</h2>
                                <p className="leading-relaxed text-base">Verbindstück muss vom Experten vorgefertigt werden für die Montage.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </section>
    );
};

export default Kuchen;
