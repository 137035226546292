import React from 'react'


const CourseCard = ({linkImg, number}) => {
  return (
    <div className='bg-white drop-shadow-md overflow-hidden my-4 mr-2'>
      <img src={linkImg} alt="courses" className='h-full w-full object-cover'/>
    </div>
  )
}

export default CourseCard