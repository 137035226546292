import React from 'react';
import NavBar from '../../components/Navbar/NavBar';
import Footer from '../../components/Footer';
import ScrollToTop from '../../components/ScrollToTop';
import { Link } from 'react-router-dom';
import CabinetIcon from '../../images/Umzuge/UmzugeAllgemeinen/1.png'
import iumzugeAllgmeinen from '../../images/Umzuge/Umzuge-im-allgemeinen.jpg'
const UmzügeAllgemeinen = () => {
    return (
        <section>
            <ScrollToTop />
            <NavBar />
            <section className="text-gray-600 body-font mt-12">
                <div className="container px-5 py-24 mx-auto flex flex-col">
                    <div className="lg:w-4/6 mx-auto">
                        <div className="rounded-lg h-64 overflow-hidden">
                            <img alt="content" className="object-cover object-center h-full w-full" src={iumzugeAllgmeinen} />
                        </div>

                        <div className="flex flex-col sm:flex-row mt-10">
                            <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                                <div className="w-20 h-20 rounded-full inline-flex items-center justify-center text-gray-400">
                                    <img alt='img' src={CabinetIcon} />
                                </div>
                                {/* <div className="flex flex-col items-center text-center justify-center">
                                    <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">Phoebe Caulfield</h2>
                                    <div className="w-12 h-1 bg-indigo-500 rounded mt-2 mb-4"></div>
                                    <p className="text-base">Raclette knausgaard hella meggs normcore williamsburg enamel pin sartorial venmo tbh hot chicken gentrify portland.</p>
                                </div> */}
                            </div>
                            <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                                <p className="leading-relaxed text-lg mb-4">
                                    Egal ob Privat, Büro, Senioren, Behörden  oder Firmenumzug. Mit uns erfahren sie einen
                                    professionellen Ablauf. Selbst bei einfachem
                                    Transport helfen wir ihnen gerne! Eine vorherige kostenlose
                                    Besichtigung gilt für uns als selbstverständlich,
                                    denn jeder Umzug ist einzigartig und mit Vertrauen verbunden.
                                    Wir helfen Ihnen gerne bei der Planung und unterstützen Sie
                                    bei eventuell auftretenden Problemen.
                                </p>
                                <p className='text-2xl mb-2 text-orange-master'>
                                    Wie können wir sie unterstützen?
                                </p>
                                <p>
                                    In welchen Bereichen Sie unsere Hilfe benötigen ist Ihnen überlassen.
                                    Beispielsweise können Sie Ihren Kleiderschrank oder Ihr Bett selbst ab- und aufbauen
                                    oder aber diese Aufgabe in unsere Hände legen. Genau so verhält es sich mit dem Packen
                                    der Kartons oder mit dem Transport des
                                    Umzugsgutes – wir stehen Ihnen im Fall der Fälle gerne beratend zur Seite!
                                </p>
                                <Link to='/angebot-anfragen' className="text-indigo-500 justify-center w-full inline-flex items-center ">
                                    <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-lg font-medium text-gray-900 rounded-lg group bg-gradient-to-r from-red-800 to-red-600 hover:bg-gradient-to-r hover:from-red-800 hover:to-red-600 hover:from-red-700 hover:to-red-800 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800">
                                        <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                                            Angebot Anfragen
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </section>
    );
};

export default UmzügeAllgemeinen;
